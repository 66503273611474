@import "@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/dist/css/bootstrap";
@import "admin-lte/build/scss/adminlte";

.layout-boxed {
  background-image: url("../images/boxed-bg.jpg");
}

body.login-page {
  .login-logo img {
    height: 4em;
  }
}

.nav-sidebar {
  .nav-header {
    text-transform: uppercase;
  }
}
